@import '../../assets/css/themeColors.scss';

.input {
    width: 2ch;
    padding: 8px;
    border-radius: 8px;
    font-size: 40px;
    text-align: center;
    margin-right: 12px;
    border: 1px solid white;
    text-transform: uppercase;
}

.otp-container {
    background-color: $hsl_blue;
    width: 100%;
    height: 100%;
    padding: 16px;
}
