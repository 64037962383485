.lsgoModal {
    position: fixed;
    z-index: 1101;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 15vh;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow-y: auto;
    max-height: 75vh;
}

.lsgoModalLarge {
    width: 800px !important;
    left: calc(50% - 400px) !important;
}

@media (min-width: 600px) {
    .lsgoModal {
        width: 600px;
        left: calc(50% - 300px);
    }
}
