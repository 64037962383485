.xls-import {
  margin-left: auto;
  width: fit-content;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }

  label {
    border: 1px dashed hsl(195, 85%, 50%);
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 1px 2px 3px #888888;
    height: 36px;
    font-family: "Museo700";
    width: 100%;
    &:hover {
      background-color: hsl(195, 85%, 50%);
    }

    span {
      vertical-align: middle;
    }
  }
}
