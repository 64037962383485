.button-release-control:hover {
    color: rgba(49, 58, 70, 0.6);
}

.release-view.active-release {
    filter: drop-shadow(8px 8px 4px hsl(195, 85%, 50%));
}

.release-carousel {
    text-align: center;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-height: 500px;
    border: 1px solid #eee;
    border-radius: 12px;

    .carousel-control {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);

        &.next {
            left: auto;
            right: 0px;
        }

        i {
            font-size: 1.2rem;
            color: #ccc;
            cursor: pointer;
        }

        &:hover {
            i {
                color: #000000aa;
            }
        }
    }

    .slides {
        display: none;

        &.active {
            display: block;
        }

        div.image-container {
            height: 340px;
            width: auto;
            position: relative;

            img {
                width: auto;
                max-width: 100%;
                height: 300px;
                margin: 20px auto;
            }
        }
    }

    .slides-count-indicator {
        position: absolute;
        top: 10px;
        left: 16px;
        color: rgba(49, 58, 70, 0.8);
    }
}

/* Fading animation */

.fading {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
