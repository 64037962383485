@import '../../assets/css/themeColors.scss';
// $contentHeight: 80vh;
// $minGameheight: 80vh;
$contentHeight: 80%;
$minGameheight: 55vh;

.studentv2 {
    overflow: auto;
    .main-logo img {
        max-height: 80px;
    }

    #progress-bar2 {
        height: 20px;
    }

    #studentLogout1 {
        background-repeat: no-repeat;
        background-size: contain;
        min-height: 100px;
    }
    #loggedInDetails1 span {
        display: inline-block;
        vertical-align: middle;
    }

    #studentSeeds1 img {
        width: 50px;
        height: 50px;
        display: block;
        text-align: center;
    }
    #studentSeeds1 span {
        display: block;
        text-align: center;
    }

    #logoutBtn1 i {
        cursor: pointer;
        display: inline;
        padding-right: 20px;
    }

    .main-student-content {
        padding-top: 0;
    }

    .panel-skills {
        width: 80%;
        margin: 0 auto;

        .student-skills-panel {
            border: 5px solid rgb(255, 204, 102);
            background: #fff;
            border-radius: 10;
        }

        .skill-count {
            text-align: center;
            margin: 10px auto;

            img {
                width: 60px;
                height: 60px;
            }

            span.points {
                color: #ffcc66;
                font-size: 22px;
                font-weight: 600;
                padding-left: 10px;
            }
        }
    }

    .student-box2 {
        position: relative;
        display: block;
        width: 80%;
        //margin: 0 auto;
        min-height: 60vh;
        //margin-top: -5px !important;
        border: 8px solid $hsl_orange;
        background-color: #fff;
        border-radius: 12px;
        margin-top: 8px;

        &.clear-border-background {
            border-color: transparent;
            background-color: transparent;
        }

        //student-box2 media size
        @media screen and (max-width: 960px) {
            min-height: 60vh;
        }

        .quiz-wrapper {
            min-height: $contentHeight;
            .quiz-content {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                min-height: $contentHeight;

                .quiz-answers {
                    min-height: $contentHeight;
                }
            }

            @media screen and (max-width: 810px) {
                .quiz-content {
                    &.text,
                    &.drawing {
                        display: block;

                        .quiz-question {
                            min-width: 100%;
                            display: block;
                            min-height: 200px;
                        }

                        .quiz-textarea {
                            width: 100%;
                            position: relative;

                            textarea {
                                height: 250px;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 620px) {
                .quiz-content {
                    display: block;

                    .quiz-question {
                        min-width: 100%;
                        display: block;
                        min-height: 200px;
                    }

                    .quiz-answers {
                        // max-height: 30vh;
                        width: 100%;
                        position: relative;
                        margin-top: 20px;
                        height: auto !important;
                    }
                }
            }
        }

        .game-content {
            width: 100%;
            min-height: $minGameheight;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            iframe {
                width: 1px;
                min-width: 100%;
                max-width: 100%;
                height: $minGameheight;
                // min-height: 500px;

                @media screen and (max-width: 768px) {
                    width: 1px;
                    max-width: 100%;
                    min-height: $minGameheight;
                }
            }
        }

        img.no-lesson-image {
            width: 100%;
        }

        .goalRewards {
            .descriptions {
                @media screen and (max-width: 768px) {
                    text-align: center;

                    .goal-input-field {
                        margin: '10px auto';
                    }
                }
            }
        }

        &.gamebox {
            width: 830px !important;
            height: 530px;
        }
    }

    &.activities-preview .student-box2 {
        width: 100% !important;
        max-height: 200px;
        border-color: transparent;
        border-width: 0px;
        border-radius: 0px;
    }

    .actionButtons {
        margin: 10px auto 20px;

        a img,
        button img {
            max-height: 50px;
        }
        a.student-main-btn {
            margin-left: 10px;
        }
    }

    .student-lion {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .weatherReport {
        display: block;

        .WR-title {
            margin-top: 40px;
        }

        .weatherGirl {
            margin-top: 32px;
            display: inline-block;
            width: 280px;
            height: 400px;

            img {
                width: 100%;
            }
        }

        .weatherIcons {
            margin-top: 40px;
            display: inline-block;
            text-align: center;
            padding: 20px;
            width: calc(100% - 280px);
            vertical-align: top;
            position: relative;

            .WR-icon {
                display: inline-block;

                &:hover,
                &.active {
                    border-bottom: 1px solid #ffa100;
                }
            }

            .moodIcons {
                padding: 16px;
                text-align: center;
                width: 75%;
                margin: 0 auto;
                display: block;
                border: 2px solid #333;
                border-radius: 10px;
                margin-top: 16px;

                .weather-activity-item {
                    display: inline-block;
                }
            }
        }

        @media screen and (max-width: 810px) {
            .weatherGirl {
                display: none;
            }

            .weatherIcons {
                width: 100%;
            }
        }
    }
}

// Responsive
@media screen and (min-width: 900px) {
    .panel-skills {
        width: 750px !important;
    }
}

@media screen and (min-height: 500) {
    .student-lion {
        position: relative;
        float: left;
    }
}

#lesson-preview-dialog {
    li.nav-item .nav-link {
        &:hover {
            background-color: $hsl_black_light;
        }
    }
}
