div.trial-register form {
    div.MuiTextField-root {
        margin-bottom: 24px;
    }

    .field-label span.required-indicator {
        color: #f00;
    }

    .button-trial-register {
        min-width: 240px;
    }
}
