.alert-dialog {
    .MuiDialogTitle-root {
        position: relative;

        span.close-dialog {
            position: absolute;
            right: 8px;
            top: 0;
            cursor: pointer;
        }
    }
}
