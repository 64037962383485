/** Platform main colors **/
$primary: #13b5ea;
$secondary: #666766;
$pink: #e20177;
$blue: #13b5ea;
$grey: #666766;
$orange: #f78e1e;
$green: #c1d82f;
$mustard: #e1d04d;
$teal: #4fb7aa;
$red: #d73816;

/** Custom Colors **/
$hsl_black: hsl(0, 0%, 0%);
$hsl_black_light: hsl(0, 0%, 70%);
$hsl_black_dark: hsl(0, 0%, 40%);

$hsl_grey: hsl(120, 0%, 40%);
$hsl_grey_light: hsl(120, 0%, 70%);
$hsl_grey_dark: hsl(120, 0%, 30%);

$hsl_blue: hsl(195, 85%, 50%);
$hsl_blue_light: hsl(195, 85%, 75%);
$hsl_blue_dark: hsl(195, 85%, 40%);

$hsl_pink: hsl(329, 99%, 45%);
$hsl_pink_light: hsl(329, 99%, 70%);
$hsl_pink_dark: hsl(329, 99%, 35%);

$hsl_orange: hsl(31, 93%, 54%);
$hsl_orange_light: hsl(31, 93%, 75%);
$hsl_orange_dark: hsl(31, 93%, 45%);

$hsl_green: hsl(68, 68%, 52%);
$hsl_green_light: hsl(68, 68%, 75%);
$hsl_green_dark: hsl(68, 68%, 45%);

$hsl_mustard: hsl(53, 71%, 59%);
$hsl_mustard_light: hsl(53, 71%, 75%);
$hsl_mustard_dark: hsl(53, 71%, 50%);

$hsl_teal: hsl(173, 42%, 51%);
$hsl_teal_light: hsl(173, 42%, 75%);
$hsl_teal-dark: hsl(173, 42%, 45%);

$hsl_white: hsl(0, 0%, 100%);
