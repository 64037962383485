@import '../../assets/css/themeColors.scss';

@mixin listReset {
    list-style: none;
    padding-inline-start: 0;
    margin-inline-start: 0;
}

@mixin elementsView {
    display: inline-block;
    width: 200px;
    padding: 16px;
    cursor: pointer;
    box-sizing: border-box;

    &:hover {
        i {
            color: $hsl_blue_light;
        }

        span {
            font-weight: 700;
        }
    }

    i.fa {
        font-size: 4rem;
        display: block;
        text-align: center;
        margin-bottom: 16px;
    }

    span.file-name {
        display: block;
        word-wrap: break-word;
    }
}

.s3file-list {
    .text-focus {
        font-weight: 700;
        font-style: italic;
    }

    div[class*='view-'] {
        & > ul.files-list {
            @include listReset();
            background-color: $hsl_grey_light;
            color: $hsl_white;
            border-radius: 8px;

            &.viewing-files {
                display: none;
            }

            & > li {
                @include elementsView();
            }
        }
    }

    div.pdf-files-view {
        background-color: $hsl_grey_light;
        color: $hsl_white;
        border-radius: 8px;
        & > span {
            display: inline-block;
            width: 200px;
            padding: 16px;

            @include elementsView();
        }
    }
}
