@import "./assets/css/themeColors.scss";
@font-face {
  font-family: "Museo700";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/Museo700.otf");
  font-display: swap;
}

html {
  background-color: #f2f2f2;
}
html,
body {
  width: 100%;
  height: 100%;
  //font-size: 13px;
  margin: 0;
  padding: 0;
  font-family: "Imprima", sans-serif;
  color: rgba(51, 51, 51, 1);

  #root {
    height: 100%;
    width: 100%;
  }

  /* Reset */
  button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
  }

  /* Custom */
  .enter {
    display: inline-block;
    position: relative;
    padding: 20px 38px;
    top: 0;
    font-size: 30px;
    font-family: "Open Sans", Helvetica;
    border-radius: 4px;
    border-bottom: 1px solid rgba(28, 227, 125, 0.5);
    background: rgba(22, 230, 137, 1);
    color: #fff;
    box-shadow: 0px 0px 0px rgba(15, 165, 60, 0.1);

    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin: 0 20px;
  }

  .enter-student {
    background: #0b56ca;
  }

  .enter:hover {
    top: -10px;
    box-shadow: 0px 10px 10px rgba(15, 165, 60, 0.2);
    color: #fff;
    -webkit-transform: rotateX(20deg);
    -moz-transform: rotateX(20deg);
    -ms-transform: rotateX(20deg);
    transform: rotateX(20deg);
  }

  .enter:active {
    top: 0px;
    box-shadow: 0px 0px 0px rgba(15, 165, 60, 0);
    background: rgba(20, 224, 133, 1);
  }
}

.information-row {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
