.backdrop{
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1100;
    left:0;
    top:0;
    background-color: rgba(0, 0, 0, 0.8);
}

.backdrop img{
    position: absolute;
    left: 50%;
    margin-left: -150px;
    top: 50%;
    margin-top: -150px;
}


