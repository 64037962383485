@import '../../../assets/css/themeColors.scss';
$blueDark: hsl(195, 85%, 40%);
$blueLight: hsl(195, 85%, 75%);
$grayDark: hsl(120, 0%, 40%);
$grayLight: hsl(120, 0%, 70%);

.hover-pointer {
    &:hover {
        cursor: pointer;
    }
}

img.appbar-logo {
    max-height: 32px;

    @media (max-width: 600px) {
        max-height: 28px;
    }
}

div.drawer-menu {
    margin-bottom: 100px;

    ul.MuiTreeView-root {
        // padding-left: 14px;
        padding-top: 20px;
        // margin-bottom: 100px;

        .MuiTreeItem-iconContainer span.space {
            width: 24px;
            display: flex;
            margin-right: 2px;
            justify-content: center;
        }

        .MuiTreeItem-label {
            font-size: 14px;
            line-height: 2;

            span.menu-dropdown,
            button,
            a {
                color: rgba(49, 58, 70, 0.8);
                display: block;
                i.fas,
                i.fab,
                i.far,
                i.fa {
                    font-size: 16px;
                    margin-right: 20px;
                }

                svg {
                    margin-right: 30px;
                    width: 23px;
                }
            }

            &:hover a {
                color: #02c0ce;
            }

            span.menu-dropdown:hover {
                i,
                span {
                    color: #02c0ce;
                }
            }
        }
    }
}

div.drawer-menu.demo {
    ul.MuiTreeView-root {
        padding-top: 40px;
    }
}

div.MuiPopover-root {
    #primary-search-account-menu,
    #header-classes-menu {
        li.MuiListOItem-root {
            a,
            button {
                display: block;
            }
        }
    }
}

#platform-notification-header {
    background-color: white;
    width: 30vw;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: black;
}

// notificatgion on header
.header-notifications-list {
    .MuiList-root.MuiMenu-list {
        width: 400px;
        padding-bottom: 0px;
    }
    li:first-of-type h6 {
        display: block;
        width: 100%;
        text-align: center;
    }

    li:not(:first-of-type) {
        padding-bottom: 0px;
        padding-top: 0px;
        box-sizing: border-box;
        border: 1px solid transparent;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;

        &.MuiListItem-gutters {
            padding-left: 0px;
            padding-right: 0px;
        }

        .header-notification-accordion {
            box-shadow: none;
            width: 100%;

            &.notification-new {
                color: $hsl_blue;
            }

            .MuiCollapse-container.MuiCollapse-entered {
                max-width: 100%;
                background-color: #f3f6f8;

                div.MuiAccordionDetails-root .notification-message {
                    width: 100%;
                }
            }

            &:last-of-type {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
        &:hover,
        &:focus {
            border-color: $hsl_blue;
        }

        &.notification-listitem-more {
            &:hover,
            &:focus {
                border-color: transparent;
            }

            .load-more-notifications {
                color: $hsl_blue;

                &:hover {
                    color: $hsl_blue_dark;
                }
            }
        }

        &:last-of-type {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    @media screen and (max-width: 767px) {
        .MuiList-root.MuiMenu-list {
            width: 100%;
            padding-bottom: 0px;
            padding: 0;
            margin: 0px;
        }

        .MuiPopover-paper {
            max-width: 100%;
            width: 100%;
            top: 0 !important;
            left: 0 !important;
            height: 100%;
            max-height: 100%;
            margin: 0;
            padding: 0;
            border-radius: 0px;
            z-index: 11000;
        }

        .notification-listitem-more {
            margin-bottom: 100px;
        }
    }
}

.buy-btns {
    &.small-screen {
        display: block;

        button.MuiButton-root {
            display: block;
            margin-left: 16px;
        }
    }

    button.MuiButton-root {
        background-color: $hsl_orange;
        color: $hsl_white;
    }
}

.drawer-expiry-info {
    hr.MuiDivider-root {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .expiry-alert {
        color: $hsl_orange_dark;
        padding-left: 16px;
        font-size: 18px;
        line-height: 2;
    }
}

//small screen appbar with buttons
@media (max-width: 600px) {
    .MuiAppBar-root .MuiToolbar-root {
        button.MuiIconButton-edgeStart {
            margin-right: 0px;
        }

        button.toolbar-expiry-days {
            margin-right: 0px;
        }

        button.user-menu-btn {
            min-width: auto;
            margin-right: 8px;
            span.teacher-name {
                display: none;
            }
        }
    }
}
